export default {
    name: "test_origami_1",
    paper:
        { height: 3, width: 4 },
    zones:
        [
            {
                name: 'recto',
                points: {
                    10: { x: 0, y: 0 },
                    1: { x: 0, y: 1 },
                    2: { x: -1, y: 0 },
                    3: { x: 0, y: -1 },
                    40: { x: 1, y: 0 },
                },
                plot_segs: [
                    { p0: 1, p1: 2 },
                    { p0: 2, p1: 3 },
                    { p0: 3, p1: 40 },
                    { p0: 40, p1: 1 },
                    { p0: 1, p1: 3 },
                    { p0: 2, p1: 40 },
                ],
                faces: [
                    { id: 'A', pts: [10, 1, 2], target_side: 'recto', Tside_fold: { a: 1, r: 0, t: { x: 4, y: 0 } } },
                    { id: 'B', pts: [10, 2, 3], target_side: 'recto', Tside_fold: { a: 1, r: 90, t: { x: 0, y: 3 } } },
                    { id: 'C', pts: [10, 3, 40], target_side: 'recto', Tside_fold: { a: 1, r: -90, t: { x: 4, y: 3 } } },
                    { id: 'D', pts: [10, 40, 1], target_side: 'verso', Tside_fold: { a: 1, r: 0, t: { x: 0, y: 0 } } },
                ],
            },
            {
                name: 'verso',
                points: {
                    0: { x: 0, y: 0 },
                    1: { x: 1, y: 0 },
                    2: { x: 1, y: 1 },
                    3: { x: 0, y: 1 },
                    4: { x: -1, y: 1 },
                    5: { x: -1, y: 0 },
                    6: { x: -1, y: -1 },
                    7: { x: 0, y: -1 },
                    8: { x: 1, y: -1 },
                },
                plot_segs: [
                    { p0: 5, p1: 1 },
                    { p0: 3, p1: 7 },
                    { p0: 2, p1: 4 },
                    { p0: 4, p1: 6 },
                    { p0: 6, p1: 8 },
                    { p0: 8, p1: 2 },
                ],
                faces: [
                    { id: 'A', pts: [0, 1, 2, 3], target_side: 'verso', Tside_fold: { a: 1, r: 180, t: { x: 4, y: 1 } } },
                    { id: 'B', pts: [0, 3, 4, 5], target_side: 'verso', Tside_fold: { a: 1, r: 90, t: { x: 1, y: 3 } } },
                    { id: 'C', pts: [0, 5, 6, 7], target_side: 'verso', Tside_fold: { a: 1, r: 0, t: { x: 4, y: 3 } } },
                    { id: 'D', pts: [0, 7, 8, 1], target_side: 'recto', Tside_fold: { a: 1, r: 0, t: { x: 0, y: 1 } } },
                ],
            },
        ]

}